// UserProvider.js
import React, { useState, useEffect, useContext, useCallback } from "react";
import userService from "../../Services/userService";
import * as signalR from "@microsoft/signalr";
import SignalRContext from "./SignalRContext";
import UserContext from "../UserContext/UserContext";
import SubjectsContext from "../SubjectsContext/SubjectsContext";
import RoutersContext from "../RoutersContext/RoutersContext";
import ToastContext from "../ToastContext/ToastContext";
import { useNavigate } from "react-router-dom";
import { Howl, Howler } from "howler";
import { useAuth0 } from "@auth0/auth0-react";
import DeviceNotificationModal from "../../Components/DeviceNotificationModal/DeviceNotificationModal";

export const SignalRProvider = ({ children }) => {
  const { userData, setUserData, wellnessAccepted } = useContext(UserContext);
  const [connection, setConnection] = useState(null);
  const [currentConnectionId, setCurrentConnectionId] = useState(null);
  const [currentSubscriptions, setCurrentSubscriptions] = useState([]);
  const [hasJoinedGroup, setHasJoinedGroup] = useState(false);
  const { logout } = useAuth0();
  const [latestSrAccessToken, setLatestSrAccessToken] = useState(null);
  const { accessToken } = useContext(UserContext);
  const { setSubjects, subjects } = useContext(SubjectsContext);
  const { setRouters } = useContext(RoutersContext);
  const { addToast } = useContext(ToastContext);
  const [isCriticalSoundPlaying, setIsCriticalSoundPlaying] = useState(false);

  const [showDeviceNotificationModal, setShowDeviceNotificationModal] =
    useState(false);
  const navigate = useNavigate();
  const MAX_CRITICAL_LOOPS =
    userData?.entityInformation?.alertPreferences?.criticalLoops || 1;

  let loopCount = 0;

  const criticalAlertSound = new Howl({
    src: ["/sounds/Critical_Alert.mp3"],
    loop: true,
    onend: function () {
      // This function will be called each time the sound finishes playing
      if (MAX_CRITICAL_LOOPS < 999999) {
        if (loopCount <= MAX_CRITICAL_LOOPS) {
          // Check if the loop count is less than the maximum allowed count
          loopCount++; // Increment the loop count
        } else {
          Howler.stop();
          loopCount = 0;
          setIsCriticalSoundPlaying(false);
        }
      }
    },
  });
  const warningAlertSound = new Howl({
    src: ["/sounds/Warning_Alert.wav"],
  });

  function getRandomDelay() {
    function getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    return getRandom(2500, 5000);
  }

  function isTokenExpired() {
    const token = latestSrAccessToken;
    if (!token) {
      return true; // No token or null token is considered expired
    }

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(window.atob(base64));
    const currentTime = Math.floor(Date.now() / 1000);

    return payload.exp < currentTime;
  }

  useEffect(() => {
    // If we already have a connection or no accessToken, there's nothing to do.
    if (connection || !accessToken || !userData) {
      return;
    }

    // No connection exists, let's negotiate a new one.
    userService
      .negotiate(accessToken)
      .then((negotiationResponse) => {
        setLatestSrAccessToken(negotiationResponse.data.accessToken);
        if (!connection && negotiationResponse && negotiationResponse.data) {
          console.log("Negotiation response:", negotiationResponse.data);
          const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(negotiationResponse.data.url, {
              accessTokenFactory: () => {
                if (isTokenExpired()) {
                  // Assuming negotiate needs the old access token to fetch a new one
                  return userService
                    .negotiate(accessToken)
                    .then((response) => {
                      setLatestSrAccessToken(response.data.accessToken); // Update the latest token
                      return response.data.accessToken;
                    })
                    .catch((error) => {
                      console.error(
                        "Error refreshing token via negotiate:",
                        error
                      );
                      throw error; // This will result in a failed connection attempt
                    });
                }
                return Promise.resolve(latestSrAccessToken);
              },
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect({
              nextRetryDelayInMilliseconds: function (retryContext) {
                return getRandomDelay();
              },
            })
            .build();

          newConnection.onreconnecting((error) => {
            console.log("SignalR Reconnect Attempt", error);
            if (
              error.toString().includes("401") ||
              error.toString().includes("expired token")
            ) {
              userService
                .negotiate(accessToken)
                .then((response) => {
                  setLatestSrAccessToken(response.data.accessToken);
                })
                .catch(() =>
                  console.log("failed to fetch latest signalr token")
                );
            }
          });
          newConnection.onreconnected((reconnectedId) => {
            setCurrentConnectionId(reconnectedId);
            console.log("RECONNECTED", reconnectedId);
          });
          newConnection.onclose((connection) => {
            console.log("Has closed", connection);

            newConnection
              .start()
              .then(() => {
                console.log("SignalR Connection restarted!");

                // Request the Web Lock to prevent tab sleep when the connection is established
                if (navigator && navigator.locks && navigator.locks.request) {
                  navigator.locks.request(
                    "signalr_lock",
                    { mode: "exclusive" },
                    () => {
                      // The lock is kept exclusive to prevent the tab from sleeping
                    }
                  );
                }
                setConnection(newConnection);
                setSubjects([]);
              })
              .catch((err) => {
                console.error(
                  "Error while establishing SignalR restart connection:",
                  err
                );
              });
          });

          newConnection
            .start()
            .then(() => {
              console.log("SignalR Connection started!");
              console.log("Connection ID:", newConnection.connectionId);
              setCurrentConnectionId(newConnection.connectionId);

              // Request the Web Lock to prevent tab sleep when the connection is established
              if (navigator && navigator.locks && navigator.locks.request) {
                navigator.locks.request(
                  "signalr_lock",
                  { mode: "exclusive" },
                  () => {
                    // The lock is kept exclusive to prevent the tab from sleeping
                  }
                );
              }
              setConnection(newConnection);
              setSubjects([]);
            })
            .catch((err) => {
              console.error(
                "Error while establishing SignalR connection:",
                err
              );
              if (
                err.toString().includes("401") ||
                err.toString().includes("expired token")
              ) {
                userService
                  .negotiate(accessToken)
                  .then((response) => {
                    setLatestSrAccessToken(response.data.accessToken);
                  })
                  .catch(() =>
                    console.log("failed to fetch latest signalr token")
                  );
              }
            });
        }
      })
      .catch((error) => {
        console.error("Negotiation failed:", error);
      });

    // Cleanup function
    return () => {
      if (connection) {
        connection.stop().then(() => console.log("Disconnected from SignalR."));
      }
    };
  }, [accessToken, connection]);

  // ----- Connection.on for Subjects ----- //
  const sendWebNotification = (subject) => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification("🚨 CRITICAL ALERT 🚨", {
            body: `${subject?.firstName} ${subject?.lastName} has ${subject?.priorityDescription}`,
            requireInteraction: true,
            data: { url: `/user/dashboard/subject/${subject.id}` }, // Set the URL you want to navigate to
          });

          notification.addEventListener("click", (event) => {
            event.target.close(); // Close the notification when clicked
            const url = event.target.data.url; // Get the URL from the notification data
            if (url) {
              window.focus();
              navigate(url);
            }
          });
        }
      });
    }
  };

  const playCriticalSounds = () => {
    if (
      !isCriticalSoundPlaying &&
      userData?.entityInformation?.alertPreferences?.criticalSounds
    ) {
      setIsCriticalSoundPlaying(true);
      criticalAlertSound.play();
      if (
        userData?.entityInformation?.alertPreferences?.criticalLoops >= 999999
      ) {
        // setShowAlertBar(true);
      }
    }
  };
  const playWarningSounds = () => {
    if (userData?.entityInformation?.alertPreferences?.warningSounds) {
      warningAlertSound.play();
    }
  };

  useEffect(() => {
    // Check if the connection is established
    if (connection && typeof connection.on === "function") {
      // Subscribe to the desired event
      connection.onreconnected(() => {
        let entityName;

        if (
          !userData?.entityInformation?.childEntities &&
          userData?.userInfo?.userRole !== "4SightAdmin"
        ) {
          entityName = userData?.userInfo?.entityName;
        }
        userService
          .getActiveMonitoringSessionsBySubscription(
            userData?.userInfo?.entityId,
            userData?.entityInformation?.entityPreference?.connectionType,
            accessToken,
            entityName
          )
          .then((response) => {
            console.log(response);
            setSubjects(response.data);
          })
          .catch((error) => {
            console.log(error);
            if (
              error.response.status === 404 &&
              (error.response.data === "No active monitoring sessions found." ||
                error.response.data ===
                  "User's location subscriptions were not found.")
            ) {
              setSubjects([]);
            }
          });
      });
      connection.on("CPProActiveSubjects", (update) => {
        // console.log("Received update: ", JSON.parse(update));
        const newDocument = JSON.parse(update);
        if (
          (userData?.entityInformation?.childEntities ||
            (!userData?.entityInformation?.childEntities &&
              userData?.userInfo?.entityName === newDocument.entityName)) &&
          userData?.userInfo?.entityId === newDocument.entityId
        )
          setSubjects((prevState) => {
            const newArray = [...prevState];

            const updateIndex = newArray.findIndex(
              (subject) => subject.id === newDocument?.id
            );

            if (updateIndex !== -1) {
              if (newDocument?.activeSessionFlag === true) {
                if (
                  newDocument?.wellnessCategory !==
                    newArray[updateIndex]?.wellnessCategory &&
                  newDocument?.wellnessCategory === "High" &&
                  (wellnessAccepted ||
                    (!wellnessAccepted &&
                      userData?.entityInformation?.alertPreferences
                        ?.wellnessAlerts))
                ) {
                  sendWebNotification(newDocument);
                  playCriticalSounds();
                  addToast(
                    "Critical Alert",
                    "Critical",
                    "danger",
                    newDocument,
                    setIsCriticalSoundPlaying
                  );
                }
                if (
                  newDocument?.wellnessCategory !==
                    newArray[updateIndex]?.wellnessCategory &&
                  newDocument?.wellnessCategory === "Elevated" &&
                  (wellnessAccepted ||
                    (!wellnessAccepted &&
                      userData?.entityInformation?.alertPreferences
                        ?.wellnessAlerts))
                ) {
                  playWarningSounds();
                }
                if (
                  newDocument?.wellnessCategory !== "High" &&
                  newArray[updateIndex]?.priorityValue !==
                    newDocument?.priorityValue
                ) {
                  if (
                    newDocument?.wellnessCategory === "Device" &&
                    userData?.entityInformation?.alertPreferences
                      ?.unknownPopups &&
                    (wellnessAccepted ||
                      (!wellnessAccepted &&
                        userData?.entityInformation?.alertPreferences
                          ?.wellnessAlerts))
                  ) {
                    addToast(
                      "Status Change",
                      "Status Change",
                      "primary",
                      newDocument,
                      null,
                      newArray[updateIndex]
                    );
                  }
                  if (
                    newDocument?.wellnessCategory === "Elevated" &&
                    userData?.entityInformation?.alertPreferences
                      ?.warningPopups &&
                    (wellnessAccepted ||
                      (!wellnessAccepted &&
                        userData?.entityInformation?.alertPreferences
                          ?.wellnessAlerts))
                  ) {
                    addToast(
                      "Status Change",
                      "Status Change",
                      "primary",
                      newDocument,
                      null,
                      newArray[updateIndex]
                    );
                  }
                }
                newArray[updateIndex] = {
                  ...newArray[updateIndex],
                  ...newDocument,
                };

                if (
                  newArray[updateIndex]?.temporaryLocation &&
                  !newDocument?.temporaryLocation
                ) {
                  delete newArray[updateIndex]?.temporaryLocation;
                }
                if (
                  newArray[updateIndex]?.officersRespondedToCritical &&
                  !newDocument?.officersRespondedToCritical
                ) {
                  delete newArray[updateIndex]?.officersRespondedToCritical;
                }
                if (newArray[updateIndex]?.deviceId && !newDocument?.deviceId) {
                  delete newArray[updateIndex]?.deviceId;
                }
                if (newArray[updateIndex]?.userId && !newDocument?.userId) {
                  delete newArray[updateIndex]?.userId;
                }
                if (
                  newArray[updateIndex]?.alertGroup &&
                  !newDocument?.alertGroup
                ) {
                  delete newArray[updateIndex]?.alertGroup;
                }
                if (
                  newArray[updateIndex]?.tier2Location &&
                  !newDocument?.tier2Location
                ) {
                  delete newArray[updateIndex]?.tier2Location;
                }
                if (
                  newArray[updateIndex]?.tier3Location &&
                  !newDocument?.tier3Location
                ) {
                  delete newArray[updateIndex]?.tier3Location;
                }
              } else {
                newArray.splice(updateIndex, 1); // Removes the item
              }
            } else {
              if (newDocument?.activeSessionFlag === true) {
                newArray.push(newDocument);
                if (
                  newDocument?.wellnessCategory === "High" &&
                  (wellnessAccepted ||
                    (!wellnessAccepted &&
                      userData?.entityInformation?.alertPreferences
                        ?.wellnessAlerts))
                ) {
                  sendWebNotification(newDocument);
                  playCriticalSounds();
                  addToast(
                    "Critical Alert",
                    "Critical",
                    "danger",
                    newDocument,
                    setIsCriticalSoundPlaying
                  );
                }
                if (
                  newDocument?.wellnessCategory === "Elevated" &&
                  (wellnessAccepted ||
                    (!wellnessAccepted &&
                      userData?.entityInformation?.alertPreferences
                        ?.wellnessAlerts))
                ) {
                  playWarningSounds();
                  if (
                    userData?.entityInformation?.alertPreferences
                      ?.warningPopups &&
                    (wellnessAccepted ||
                      (!wellnessAccepted &&
                        userData?.entityInformation?.alertPreferences
                          ?.wellnessAlerts))
                  ) {
                    addToast(
                      "Status Change",
                      "Status Change",
                      "primary",
                      newDocument,
                      null,
                      newArray[updateIndex]
                    );
                  }
                }
                if (
                  newDocument?.wellnessCategory === "Device" &&
                  userData?.entityInformation?.alertPreferences
                    ?.unknownPopups &&
                  (wellnessAccepted ||
                    (!wellnessAccepted &&
                      userData?.entityInformation?.alertPreferences
                        ?.wellnessAlerts))
                ) {
                  addToast(
                    "Status Change",
                    "Status Change",
                    "primary",
                    newDocument,
                    null,
                    newArray[updateIndex]
                  );
                }
              }
            }

            return newArray;
          });
      });

      return () => {
        connection.off("CPProActiveSubjects");
      };
    }
  }, [connection]);

  // ----- Connection.on for entity information ----- //
  useEffect(() => {
    // Check if the connection is established
    if (connection && typeof connection.on === "function") {
      // Subscribe to the desired event
      connection.onreconnected(() => {
        // refetch userInfo here later
      });

      connection.on(userData?.userInfo?.entityId + "-information", (update) => {
        // console.log("Received a user update", update);
        const newDocument = JSON.parse(update);
        if (newDocument?.entityRoles) {
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.entityInformation.entityRoles = newDocument?.entityRoles;

            return copy;
          });
        }
        if (newDocument?.entityTitles) {
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.entityInformation.entityTitles = newDocument?.entityTitles;

            return copy;
          });
        }
        if (newDocument?.email) {
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.entityInformation.email = newDocument?.email;

            return copy;
          });
        }
        if (newDocument?.adIntegration !== undefined) {
          // console.log("Received a user update", newDocument?.adIntegration);
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.entityInformation.entityPreference.adIntegration =
              newDocument?.adIntegration;

            return copy;
          });
        }
        if (newDocument?.entityLogoUrl) {
          // console.log("Received a user update", newDocument?.entityLogoUrl);
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.entityInformation.entityLogoUrl = newDocument?.entityLogoUrl;

            return copy;
          });
        }
        if (newDocument?.alertGroups) {
          setUserData((prevState) => {
            const copy = { ...prevState };

            copy.entityInformation.alertGroups = newDocument?.alertGroups;

            return copy;
          });
        }
      });

      return () => {
        connection.off(userData?.userInfo?.entityId + "-information");
      };
    }
  }, [connection]);

  // ----- Connection.on for users ----- //

  const triggerDeviceNotificationModal = useCallback(() => {
    console.log("triggerDeviceNotificationModal called");
    const HOUR_IN_MS = 60 * 60 * 1000;

    const isThereDeviceSubjectWithOldPVChange = subjects.some((subject) => {
      if (subject.wellnessCategory !== "Device") {
        console.log(
          `Subject ID: ${subject.id} skipped (Not a Device category).`
        );
        return false;
      }
      if (!subject.pvChangeTimeStamp) {
        console.log(
          `Subject ID: ${subject.id} skipped (No pvChangeTimeStamp).`
        );
        return false;
      }

      const elapsedTime = Date.now() - subject.pvChangeTimeStamp;
      console.log(`Subject ID: ${subject.id}, Elapsed Time: ${elapsedTime} ms`);

      return elapsedTime > HOUR_IN_MS;
    });

    console.log(
      "Is there a device subject with old PV change?",
      isThereDeviceSubjectWithOldPVChange
    );

    if (isThereDeviceSubjectWithOldPVChange) {
      console.log("Setting showDeviceNotificationModal to true.");
      setShowDeviceNotificationModal(true);
    } else {
      console.log("No device subjects meet the criteria.");
    }
  }, [subjects]);

  console.log(subjects);
  useEffect(() => {
    // Check if the connection is established
    if (connection && typeof connection.on === "function") {
      // Subscribe to the desired event
      connection.onreconnected(() => {
        // refetch userInfo here later
      });

      connection.on(userData?.userInfo?.id, (update) => {
        // console.log("Received a user update", update);
        const newDocument = JSON.parse(update);
        console.log("USER DOC", newDocument);
        if (newDocument?.userLocationSubscription) {
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.userInfo.userLocationSubscription =
              newDocument?.userLocationSubscription;

            return copy;
          });
        }
        if (
          newDocument?.deleted &&
          userData?.userInfo.id === newDocument?.deleted
        ) {
          logout({ logoutParams: { returnTo: window.location.origin } });
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            delete copy.userInfo;
            delete copy.entityInformation;
            return copy;
          });
        }
        if (newDocument?.userRole) {
          // console.log("SIGNALR user role", newDocument?.userRole);
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.userInfo.userRole = newDocument?.userRole;

            return copy;
          });
        }
        if (newDocument?.title) {
          // console.log("SIGNALR user role", newDocument?.userRole);
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.userInfo.title = newDocument?.title;

            return copy;
          });
        }
        if (newDocument?.darkTheme !== undefined) {
          console.log("SIGNALR user theme", newDocument?.darkTheme);
          // triggerDeviceNotificationModal();
          setUserData((prevState) => {
            const copy = { ...prevState };
            // console.log(copy);
            copy.userInfo.darkTheme = newDocument?.darkTheme;

            return copy;
          });
        }
        if (newDocument?.showDeviceNotification) {
          console.log(
            "SHOW DEVICE NOTIFICation",
            newDocument?.showDeviceNotification
          );
          triggerDeviceNotificationModal();
        }
      });

      return () => {
        connection.off(userData?.userInfo?.id);
      };
    }
  }, [connection, triggerDeviceNotificationModal]);
  // ----- Connection.on for Routers -----//

  useEffect(() => {
    // Check if the connection is established
    if (connection && typeof connection.on === "function") {
      // Subscribe to the desired event
      connection.onreconnected(() => {
        let entityName;

        if (
          !userData?.entityInformation?.childEntities &&
          userData?.userInfo?.userRole !== "4SightAdmin"
        ) {
          entityName = userData?.userInfo?.entityName;
        }

        userService
          .getEntityRouters(
            userData?.userInfo?.entityId,
            accessToken,
            entityName
          )
          .then((response) => {
            setRouters(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
      connection.on("CPProRouters", (update) => {
        // console.log("Received update routers: ", update);
        const newDocument = JSON.parse(update);
        if (
          (userData?.entityInformation?.childEntities ||
            (!userData?.entityInformation?.childEntities &&
              userData?.userInfo?.entityName === newDocument.entityName)) &&
          userData?.userInfo?.entityId === newDocument.entityId
        )
          setRouters((prevState) => {
            const newArray = [...prevState];

            const updateIndex = newArray.findIndex(
              (subject) => subject.id === newDocument.id
            );

            if (updateIndex !== -1) {
              newArray[updateIndex] = { ...newDocument };
            } else {
              newArray.push(newDocument);
            }

            return newArray;
          });
      });

      return () => {
        connection.off("CPProRouters");
      };
    }
  }, [connection]);

  // ----- Joining SignalRGroups for Subjects -----//

  useEffect(() => {
    const performLeaveOperation = async () => {
      if (hasJoinedGroup && currentSubscriptions.length > 0) {
        const leavePayload = {
          connectionId: currentConnectionId,
          groupNames: currentSubscriptions,
        };
        try {
          const leaveResponse = await userService.removeSignalRGroupsV2(
            leavePayload,
            accessToken
          );
          console.log("Left Subscription group successfully", leaveResponse);

          // Now that we've left the old group, perform the join operation
          performJoinOperation();
        } catch (leaveError) {
          console.error("Error leaving group:", leaveError);
        }
      } else {
        // No need to leave, directly perform the join operation
        performJoinOperation();
      }
    };

    const performJoinOperation = async () => {
      // Transform the userLocationSubscription data as previously discussed
      const transformedData =
        userData?.userInfo?.userLocationSubscription.reduce((result, site) => {
          const entityId = userData?.userInfo?.entityId;

          if (!site.locations || site.locations.length === 0) {
            // Concatenate entity ID and site if there are no locations
            const siteLocation = entityId + site.site;
            result.push(siteLocation);
          } else {
            site.locations.forEach((location) => {
              if (location.housingUnits && location.housingUnits.length > 0) {
                location.housingUnits.forEach((unit) => {
                  // Concatenate entity ID, site, location, and unit
                  const siteLocationUnit =
                    entityId + site.site + location.location + unit;
                  result.push(siteLocationUnit);
                });
              } else {
                // Concatenate entity ID, site, and location if no units
                const siteLocationNoUnit =
                  entityId + site.site + location.location;
                result.push(siteLocationNoUnit);
              }
            });
          }

          return result;
        }, []);

      const newSubscriptions = transformedData;

      // Logging

      // Check if subscriptions have changed
      if (userData?.userInfo?.userLocationSubscription) {
        // Logging
        console.log("Subscriptions have changed. Performing join operation...");

        // Perform join operation for the new subscriptions
        const joinPayload = {
          connectionId: currentConnectionId,
          groupNames: newSubscriptions,
        };
        try {
          const joinResponse = await userService.joinSignalRGroupsV2(
            joinPayload,
            accessToken
          );
          console.log("Subscription Group Joined Successfully", joinResponse);
          setHasJoinedGroup(true);

          // Fetch additional data if needed
          try {
            let entityName;

            if (
              !userData?.entityInformation?.childEntities &&
              userData?.userInfo?.userRole !== "4SightAdmin"
            ) {
              entityName = userData?.userInfo?.entityName;
            }

            const additionalDataResponse =
              await userService.getActiveMonitoringSessionsBySubscription(
                userData?.userInfo?.entityId,
                userData?.entityInformation?.entityPreference?.connectionType,
                accessToken,
                entityName
              );
            console.log(additionalDataResponse);
            setSubjects(additionalDataResponse.data);
          } catch (additionalDataError) {
            if (
              additionalDataError.response.status === 404 &&
              (additionalDataError.response.data ===
                "No active monitoring sessions found." ||
                additionalDataError.response.data ===
                  "User's location subscriptions were not found.")
            ) {
              setSubjects([]);
            }
            console.log("Error fetching additional data:", additionalDataError);
          }
        } catch (joinError) {
          console.error("Error joining group:", joinError);
        }

        // Update the current subscriptions
        setCurrentSubscriptions(newSubscriptions);
      } else {
        // Logging
        console.log("Subscriptions have not changed.");
      }
    };

    if (
      connection &&
      typeof connection.on === "function" &&
      userData?.userInfo?.entityId &&
      accessToken &&
      userData?.userInfo?.userLocationSubscription?.length >= 0 &&
      userData?.entityInformation?.entityPreference?.connectionType &&
      currentConnectionId
    ) {
      // First, perform the leave operation (if necessary)
      performLeaveOperation();
    }
  }, [
    connection,
    userData?.userInfo?.entityId,
    accessToken,
    userData?.userInfo?.userLocationSubscription,
    userData?.entityInformation?.entityPreference?.connectionType,
    currentConnectionId,
    userData?.entityInformation?.childEntities,
    userData?.userInfo?.userRole,
  ]);

  // ----- Joining SignalRGroups for Routers -----//

  useEffect(() => {
    let hasJoinedGroup = false;
    const payload = {
      connectionId: currentConnectionId,
      groupNames: [`Routers-${userData?.userInfo?.entityId}`],
    };
    let entityName;
    if (
      connection &&
      userData?.userInfo?.entityId &&
      accessToken &&
      currentConnectionId
    ) {
      if (
        !userData?.entityInformation?.childEntities &&
        userData?.userInfo?.userRole !== "4SightAdmin"
      ) {
        entityName = userData?.userInfo?.entityName;
      }
      userService
        .joinSignalRGroupsV2(payload, accessToken)
        .then((response) => {
          console.log("Joined Routers Successfully", response);
          hasJoinedGroup = true;
          userService
            .getEntityRouters(
              userData?.userInfo?.entityId,
              accessToken,
              entityName
            )
            .then((response) => {
              setRouters(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.error("Error joining group:", error);
        });
    }

    // Cleanup function
    return () => {
      if (hasJoinedGroup) {
        userService
          .removeSignalRGroupsV2(payload, accessToken)
          .then((response) => {
            console.log("Left group successfully", response);
          })
          .catch((error) => {
            console.error("Error leaving group:", error);
          });
      }
    };
  }, [
    connection,
    userData?.userInfo?.entityId,
    accessToken,
    userData?.userData?.entityName,
    userData?.entityInformation?.childEntities,
    currentConnectionId,
  ]);

  return (
    <React.Fragment>
      <DeviceNotificationModal
        userData={userData}
        show={
          showDeviceNotificationModal
          // true
        }
        handleClose={() => setShowDeviceNotificationModal(false)}
        title={`NOT BEING MONITORED`}
        subjects={subjects}
        accessToken={accessToken}
        confirmText={"Acknowledge"}
      />
      <SignalRContext.Provider value={{ connection, currentConnectionId }}>
        {children}
      </SignalRContext.Provider>
    </React.Fragment>
  );
};
