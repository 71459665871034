import React, { useState, useMemo } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "../confirmingmodal.css";
import userService from "../../Services/userService";
import "./devicenotificationmodal.css";

function DeviceNotificationModal({
  userData,
  title,
  children,
  show,
  handleClose,
  accessToken,
  confirmText = "Confirm",

  subjects,
}) {
  const [startedAcknowledge, setStartedAcknowledge] = useState(false);

  const confirmationButtonStyle = {
    backgroundColor: null,
  };

  const getSubjectsName = (subject) => {
    return subject?.firstName && subject?.lastName
      ? `${subject?.firstName} ${subject?.lastName}`
      : subject?.lastName
      ? subject?.lastName
      : subject?.firstName
      ? subject?.firstName
      : "--";
  };

  const groupedSubjectsByPv = useMemo(() => {
    if (!subjects) return {};

    const ONE_HOUR_IN_MS = 60 * 60 * 1000;
    const currentTime = Date.now();

    // Step 1: Filter
    const filteredSubjects = subjects.filter((subject) => {
      const { wellnessCategory, priorityValue, pvChangeTimeStamp } = subject;
      return (
        wellnessCategory === "Device" &&
        priorityValue !== 110 &&
        priorityValue !== 29 &&
        currentTime - pvChangeTimeStamp >= ONE_HOUR_IN_MS
      );
    });

    // Step 2: Group by priorityValue
    const grouped = filteredSubjects.reduce((acc, subject) => {
      const { priorityValue } = subject;
      // If we haven’t encountered this priorityValue yet, initialize its array
      if (!acc[priorityValue]) {
        acc[priorityValue] = [];
      }
      acc[priorityValue].push(subject);
      return acc;
    }, {});

    return grouped;
  }, [subjects]);

  const getElapsedTimeString = (timestamp) => {
    const currentTime = Date.now();
    const diffMs = currentTime - timestamp;

    // Convert differences
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      return diffDays === 1 ? "1 day" : `${diffDays} days`;
    } else if (diffHours > 0) {
      return diffHours === 1 ? "1 hr" : `${diffHours} hrs`;
    } else if (diffMinutes > 0) {
      return diffMinutes === 1 ? "1 min" : `${diffMinutes} mins`;
    } else {
      // Fallback to seconds
      return diffSeconds === 1 ? "1 sec" : `${diffSeconds} secs`;
    }
  };

  const getWellnessPriorityDescription = (priorityValue) => {
    const priorityValueIndex = userData?.entityAssets?.alertStates?.findIndex(
      (state) => state.priorityValue === Number(priorityValue)
    );

    const priorityDescription =
      priorityValueIndex !== -1
        ? userData?.entityAssets?.alertStates[priorityValueIndex]
            ?.wellnessPriorityDescription
        : userData?.entityAssets?.alertStates[
            userData?.entityAssets?.alertStates?.length - 1
          ]?.priorityDescription;

    return priorityDescription;
  };

  const onAcknowledgeClicked = () => {
    setStartedAcknowledge(true);
    const flattenedSubjects = Object.entries(groupedSubjectsByPv).flatMap(
      ([priorityValue, subjectsArr]) => {
        return subjectsArr.map((subject) => ({
          subjectId: subject.id,
          priorityValue: subject.priorityValue,
        }));
      }
    );

    const payload = {
      entityId: userData?.userInfo?.entityId,
      subjects: flattenedSubjects,
    };
    userService
      .createAcknowledgeDeviceNotification(payload, accessToken)
      .then((response) => {
        console.log("Acknowledged", response);
        handleClose();
        setStartedAcknowledge(false);
      })
      .catch((error) => {
        console.log("Acknowledge Failed", error);
        setStartedAcknowledge(false);
      });
  };
  return (
    <>
      <Modal
        centered
        show={show}
        style={{ zIndex: 2000 }}
        dialogClassName="modal-90w"
        backdropClassName="device-notification-confirming-modal-backdrop"
        className={`device-notification-modal ${
          userData?.userInfo?.darkTheme && "confirming-modal-container"
        }`}
      >
        <div
          className="modal-main-confirmation-info-container"
          // style={{ background: "red" }}
        >
          <div
            className="modal-top-component-container"
            style={{ display: "grid" }}
          >
            <div className="confirming-modal-header-text-group">
              <div
                className="confirming-modal-title"
                style={{
                  color: userData?.userInfo?.darkTheme
                    ? "#F5F5F6"
                    : "var(--colors-text-text-primary-900)",
                  fontSize: 18,
                }}
              >
                {title}
              </div>
              <div
                style={{
                  color: userData?.userInfo?.darkTheme
                    ? "#94969C"
                    : "var(--colors-text-text-tertiary-600)",
                  fontSize: 14,
                }}
              >
                Address these alerts so you can be notified when there might be
                a crisis.
              </div>

              <div
                className="confirming-modal-description"
                style={{
                  color: userData?.userInfo?.darkTheme
                    ? "#94969C"
                    : "var(--colors-text-text-tertiary-600)",
                  display: "grid",
                  gap: 24,
                  marginTop: 24,
                }}
              >
                {Object.entries(groupedSubjectsByPv).length ? (
                  Object.entries(groupedSubjectsByPv).map(
                    ([priorityValue, subjectsInGroup]) => (
                      <div
                        key={priorityValue}
                        style={{
                          marginBottom: "1rem",
                          display: "grid",
                          gap: 12,
                        }}
                      >
                        <div
                          style={{
                            color: userData?.userInfo?.darkTheme
                              ? "#F5F5F6"
                              : "var(--colors-text-text-primary-900)",
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                        >
                          {getWellnessPriorityDescription(priorityValue)} (
                          {subjectsInGroup.length})
                        </div>
                        <div
                          style={{
                            color: userData?.userInfo?.darkTheme
                              ? "#94969C"
                              : "var(--colors-text-text-tertiary-600)",
                            fontSize: 14,
                            gap: 8,
                            display: "grid",
                          }}
                        >
                          {subjectsInGroup.map((subject) => (
                            <div key={subject.id}>
                              {getSubjectsName(subject)} -{" "}
                              {getElapsedTimeString(subject.pvChangeTimeStamp)}{" "}
                              in state
                            </div>
                          ))}{" "}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div>No subjects match the criteria.</div>
                )}
              </div>
            </div>
          </div>
          {/* {!startedAcknowledge && (
            <img
              src="/svgs/Modal/x-close.svg"
              alt="close"
              className="confirming-modal-x-button-close"
              onClick={handleClose}
            />
          )} */}

          {!startedAcknowledge ? (
            <div className="confirming-modal-button-group">
              <div
                className="confirming-modal-submit-button"
                style={confirmationButtonStyle}
                onClick={onAcknowledgeClicked}
              >
                {confirmText}
              </div>
            </div>
          ) : (
            <div className="text-center">
              <Spinner />{" "}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default DeviceNotificationModal;
